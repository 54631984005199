<template>
  <div class="body">

    <div id="app">

      <section class="top-header topHeader" v-bind:class="canStick()" v-if="current_page != 'print-game' "
        v-show="current_page != 'casinogames'">
        <div class="d-flex justify-content-between deposit_page"
          v-if="current_page == 'deposit' && current_page != 'casinogames'">
          <div class="d-flex justify-around align-center text-light">
            <div class="s-backButton btn" @click="goBack"><i class="bi bi-chevron-left"></i> Back</div>
          </div>
          <div class="d-flex justify-around">
            <div @click="goTo('deposit-how-to')" class="mr-3 text-light" style="font-size:13px"><i
                class="bi bi-question-circle d-none"></i>How Do I Deposit</div>
            <router-link to="/"><i style="color: black;" class="bi bi-house-door-fill"></i></router-link>
          </div>
        </div>

        <div class="row p-0 m-0" style="background-color: #12121e; " v-if="!$route.meta.hideHeader"
          v-show="current_page != 'deposit' && current_page != 'casinogames'">


          <div class="col-3 px-0 pt-1 pb-1 m-0 text-center logo">
            <router-link to="/">
              <img style="width: 50px;" src="/img/falconBet.png" class="top-header-logo img-fluid" alt="FalconBet Logo">
            </router-link>
          </div>

          <div class="col-6 px-0 pt-1 pb-1 m-0 text-center logo">

          </div>

          <div style="float: right;" class="col-3 p-0 m-0 login" v-if="!$route.meta.hideHeader">

            <div v-if="profile" class=" d-flex depo text-center justify-content-around ">
              <router-link style="margin-top: 10px;" to="/gifts">
                <img src="/img/topNav/gift.png" class="wallet-icon"> <br>
                <span style="color: var(--yellow);" class="bet-history wallet-text">
                  <strong>Ksh{{ formatCurrency(myProfile.b5) }} </strong>
                </span>
              </router-link>
              <router-link style="margin-top: 10px;" to="/my-account">
                <img src="/img/topNav/money.png" class="wallet-icon"> <br>
                <span style="color: var(--yellow);" class="wallet-balance wallet-text">
                  <strong>Ksh<span v-text="bal"></span></strong>
                </span>
              </router-link>
            </div>

            <div v-else class="d-flex align-items-center justify-content-center text-center pr-2 pt-1 d-none"
              style="height: 90%; margin-right: 3px">
              <router-link v-show="current_page != 'login'" to="/login"
                class="header-login-button small-text mr-1 d-flex align-items-center justify-content-center"
                style="height: 70%;">
                Login
              </router-link>

              <router-link v-show="current_page != 'join'" to="/join"
                class="header-join-button darkBackground small-text d-flex align-items-center justify-content-center" 
                style="height: 70%; color: #fff !important; ">
                Register
              </router-link>
            </div>
          </div>

        </div>

      </section>

      <!--A-Z Menu-->
      <div id="menu-modal" class="faso-menu-modal" style="z-index: 99999;">
        <!-- Modal content -->
        <div class="faso-menu-modal-content">
          <section class="w-100 menu-bg">
            <div style="border-bottom: solid #fdb812 2px;" class="row m-0 p-0 side-menu-header">
              <div class="col-4 m-0 p-0">
                <div class="p-3 px-3">
                  <strong @click.native="closeMenu" class="text-light faso-menu-close">Close</strong>
                </div>
              </div>
              <div class="col-4 text-center">

              </div>
              <div class="col-4 text-right m-0 p-0">
                <div class="p-3 faso-menu-close">
                  <strong @click.native="closeMenu" class="text-light" id="menu-close"><i class="bi bi-chevron-left"></i>
                    Back</strong>
                </div>
              </div>
            </div>

            <div style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/casino" @click.native="closeMenu">
                <a class="flat-link d-flex align-items-center">
                  <img src="/img/topNav/casino.png" class="side-icon-svg mr-2">
                  <span class="text-light">Casino</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>

            <div style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/my-account" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/img/topNav/user.png" class="side-icon-svg mr-2">
                  <span class="text-light">My Profile</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>
            <div v-if="showAffiliateDashboard" style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/referral" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/img/topNav/share.png" class="side-icon-svg mr-2">
                  <span class="text-light">Affiliate</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>
            <div v-else style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/affiliates" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/img/topNav/share.png" class="side-icon-svg mr-2">
                  <span class="text-light">Affiliates Dashboard</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>

            <div style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/my-account" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/img/topNav/wallet.png" class="wallet-icon mr-2">
                  <span class="text-light">My Wallet</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>

            <div style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/responsible-gaming" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/img/topNav/18.png" class="side-icon-svg mr-2">
                  <span class="text-light">Responsible Gaming</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>

            <div style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/faq" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/img/topNav/message.png" class="side-icon-svg mr-2">
                  <span class="text-light">Frequently Asked Questions</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>

            <div style="border-bottom: solid #fdb812 1px;"
              class="d-flex justify-content-between align-items-center px-3 py-1 flatlink-div">
              <router-link to="/terms" @click.native="closeMenu">
                <a class="flat-link">
                  <img src="/img/topNav/list.png" class="side-icon-svg mr-2">
                  <span class="text-light">Terms and Conditions</span>
                </a>
              </router-link>
              <i class="bi bi-chevron-right text-light"></i>
            </div>

            <div class="d-flex justify-content-left px-3 py-1 flatlink-div">
              <a @click="logout" class="flat-link">
                <img src="/img/topNav/logout.png" class="side-icon-svg mr-2">
                <span class="text-danger">Logout</span>
              </a>
            </div>
          </section>
        </div>
      </div>

      

      <!--End A-Z Menu-->

      <span id="menu-init" class="hidden"></span>
      <span class="faso-menu-close hidden"></span>

      <router-view></router-view>

      
      <section v-if="!$route.meta.hideMenu && !$route.meta.hideHeader" class="contact-area" id="contact">
        <div class="container">
          <div class="row">
            <div class="">
              <div class="text-center">
                <p style="text-align: center;">
                  Falcon Bet is licensed by BCLB (Betting 
                  Control and Licensing Board) under the Betting, 
                  Lotteries and Gaming Act, Cap 131 Laws of Kenya 
                  License Number: BK0000773. 

                </p>
                <p style="text-align: center; padding-left: 5px;padding-right: 5px;">
                  Must be 18 years of age or older to register or play
                   at Falcon Bet. 
                  Gambling may have adverse effects if not done with
                   moderation.
                </p>
                <div class="hr"></div>
                <p><span> <i class="bi bi-telephone"></i> </span> +254769333888<span>|</span>  <span> <i class="bi bi-whatsapp"></i> </span>  +254768333999<span>|</span>  <span> <i class="bi bi-envelope"></i></span>  support@falconbet.co.ke</p>
                <div class="contact-social">
                  <ul>
                    <li><a class="hover-target" href="https://www.facebook.com/profile.php?id=61565397608870&mibextid=ZbWKwL"><i class="bi bi-facebook"></i></a></li>
                    <li><a class="hover-target" href="https://www.instagram.com/falconbet_ke?igsh=MWd0YnA3OTJ1bTQ2ZQ=="><i class="bi bi-instagram"></i></a></li>
                    <li><a class="hover-target" href="https://www.tiktok.com/@falconbet_ke?_t=8pert67AWrG&_r=1"><i class="bi bi-tiktok"></i></a></li>
                     <li><a class="hover-target" href="https://x.com/Falconbet2024?t=NSFAAaOrHdGPZfhVizrWTg&s=09"> <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-twitter-x" viewBox="0 0 16 16">
                    <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                    </svg></a></li> 
                   
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <footer v-if="!$route.meta.hideMenu && !$route.meta.hideHeader">
        <p>Copyright &copy; All Rights Reserved.</p>
      </footer>

      <div class="depo-widget d-none" id="dpo-widg" v-show="current_page != 'deposit'">
        <span id="dpo-close" class="text-light" @click="closeWidget()"><i class="bi bi-x-lg"></i></span>
        <router-link to="/deposit" class="deposit-link" v-show="current_page != 'deposit'">
          <img src="/img/depo.webp" class="depo-widg">
        </router-link>
      </div>
      
    </div>

  </div>
</template>

<script>
import mqtt from "mqtt";
import axios from "@/services/api";

export default {
  name: 'app',
  components: {
  },
  mounted: function () {

    var vm = this;

    vm.myProfile = vm.getProfile();
    this.initMenuModal();
    this.getSports();
    setInterval(function () {

      vm.myProfile = vm.getProfile();

    }, 2000);
    this.EventBus.$on('deposit:popup', function (payload) {
      vm.amount = payload.amount;
      vm.message = payload.message;
      if (document.getElementById("deposit-init") != null) {
        document.getElementById("deposit-init").click();
      }

    });

    this.EventBus.$on('init:mqtt', function () {

      console.log('Wants Inititalize MQTT');

      if (vm.mqttClient !== false) {

        vm.mqttClient.end();
        vm.mqttClient = false;
      }

      vm.myProfile = vm.getProfile();
      vm.initMqtt();

    });

    vm.initMqtt();

    var params = this.getURLParameters();

    var utm_source = params.utm_source ? params.utm_source : ''
    var utm_medium = params.utm_medium ? params.utm_medium : ''
    var utm_campaign = params.utm_campaign ? params.utm_campaign : ''
    var referral_code = params.p ? params.p : ''
    var referrer = document.referrer
    var btag = params.btag ? params.btag : ''
    console.log('GOT referrer ' + referrer)

    if (btag.length > 0) {
      this.setValue('btag', btag)
    }

    if (utm_source.length > 0) {

      this.setValue('utm_source', utm_source)
    }

    if (utm_medium.length > 0) {

      this.setValue('utm_medium', utm_medium)
    }

    if (referral_code.length > 0) {

      this.setValue('referral_code', referral_code)
    }

    if (utm_campaign.length > 0) {

      this.setValue('utm_campaign', utm_campaign)
    }
    this.setValue('referrer', referrer)
  },
  computed: {
    showAppBanner: function () {
      if (this.$store.state.show_app_banner) {
        return '';
      } else {
        return 'd-none';
      }
    },
    bal: function () {

      return this.formatCurrency(this.profile.b1);

    },
    full_name: function () {

      if (this.profile === undefined) {

        return ""
      }

      if (this.profile.f1 === undefined || this.profile.f1.length === 0) {

        return ms;

      }

      var fn = this.profile.f1;
      var mn = this.profile.f2;
      var ln = this.profile.f3;
      var ms = this.getUIValue(this.profile.m);
      return fn + ' ' + mn + ' ' + ln + ' - ' + ms;

    },
    profile: function () {

      return this.myProfile;

    },
    current_page: function () {

      return this.$store.state.current_page;

    },
    sports: function () {
      var s = this.$store.state.sports;
      var t = [];

      this.jQuery.each(s, function (k, v) {

        if (v.sport_id > 1) {

          t.push(v);
        }

      })

      return t;
    },
    homePageFontColor: function () {

      if (this.current_page === 'home') {

        return "yellow-txt";
      }

      return "";

    },
    livePageFontColor: function () {

      if (this.current_page === 'live') {

        return "yellow-txt";
      }

      return "";

    },
    historyPageFontColor: function () {

      if (this.current_page === 'history') {

        return "yellow-txt";
      }

      return "";

    },
    betslip: function () {

      //var bSlip = this.getObject("betslip");
      //return bSlip;
      return this.$store.state.betslip
    },
    activeBets: function () {

      var p = this.getProfile();

      if (!p) {

        return 0;

      }

      return p.b

    },
    betslip_count: function () {

      if (!this.betslip.total || this.betslip.total == "") {

        return 0
      }

      return parseInt(this.betslip.total);

    },

  },
  methods: {
    closeMenu() {
      document.getElementById("menu-modal").style.display = "none";
    },
    canStick: function () {
      if (this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'virtuals') {
        return '';
      } else {
        return 'header-stick';
      }
    },
    canFeatureStick: function () {
      if (this.$store.state.current_page == 'match' || this.$store.state.current_page == 'casinogames' || this.$store.state.current_page == 'casino' || this.$store.state.current_page == 'casino-live' || this.$store.state.current_page == 'virtuals') {
        return '';
      } else {
        return 'feature-stick';
      }
    },
    isActive: function (page) {

      return this.current_page === page ? 'active' : '';

    },

    setActive: function (menu) {

      this.current_menu = menu;

    },
    livescore: function () {


    },

    initMqtt: function () {

      var endpoint = process.env.VUE_APP_URL_MQTT_HOST;
      var vm = this;


      if (this.mqttClient !== false) {

        this.mqttClient.end();

      }

      // connection option

      const options = {
        clean: true, // retain session
        connectTimeout: 4000, // Timeout period
        // Authentication information
        clientId: this.getClientID(),
        username: process.env.VUE_APP_URL_MQTT_USER,
        password: process.env.VUE_APP_URL_MQTT_PASS,
      }

      console.log('at endpoint ' + endpoint)

      var client = mqtt.connect(endpoint, options);

      client.on('connect', function () {

        console.log('connected here')

        if (vm.myProfile) {

          var profileTopic = 'topic/profile/' + vm.myProfile.id;
          profileTopic = profileTopic.toLowerCase();

          client.subscribe(profileTopic, function (err) {

            if (!err) {

              console.log('subscribed to topic ' + profileTopic);

            }

          });
        }

      })

      client.on('message', function (topic, msg) {
        // message is Buffer
        var payload = JSON.parse(msg.toString());
        vm.uxUpdate(payload);
      })

      this.mqttClient = client;

    },

    getMarket: function (sport_id) {

      this.$store.dispatch("getMarkets", sport_id);
    },
    setSport: function (sport) {

      this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      this.sport = sport;
      this.sport_name = sport.sport_name;
      this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport.sport_id);
      this.goHome();
    },
    setSportMenu: function (sport_id, sport_name) {

      // this.dismiss();
      //console.log('setSport ' + JSON.stringify(sport));

      // this.sport = sport;
      this.sport_name = sport_name;
      // this.getMarket(sport.sport_id);
      this.$store.dispatch("setSportID", sport_id);
      this.$store.dispatch("setCurrentPage", sport_name);
      console.log(this.$store.state.sport_id)

      //if(this.$route.path !== "/"){

      this.$router.push({ name: 'sport', params: {} });
      //}

    },



    initMenuModal: function () {

      // Get the modal
      var modal = document.getElementById("menu-modal");

      // Get the button that opens the modal
      var btn = document.getElementById("menu-init");

      // Get the <span> element that closes the modal
      var span = document.getElementsByClassName("faso-menu-close")[0];

      // When the user clicks on <span> (x), close the modal
      span.onclick = function () {
        modal.style.display = "none";
        document.body.style.position = '';
      }

      // When the user clicks the button, open the modal
      btn.onclick = function () {
        modal.style.display = "block";
        //document.body.style.position = 'fixed';
      }

      document.addEventListener("click", e => {

        if (e.target == document.querySelector("menu-modal")) {

          modal.style.display = "none";
        }

      });

      // When the user clicks anywhere outside of the modal, close it
      window.onclick = function (event) {

        if (event.target === modal) {

          modal.style.display = "none";

        }
      }

    },

    initDeposit: function () {

      this.dismiss();
      this.$router.push({ name: 'deposit' });
    },
    dismiss: function () {

      console.log('DISMISS MENU');
      var modal = document.getElementById("menu-modal");
      modal.style.display = "none";

    },

    getKey: function (index) {

      return Math.random().toString(10).replace('0.', 'sport-' + index + '-');

    },
    getSports: function () {
      this.$store.dispatch("getSports");
    },
    setAmount: function (amount) {

      this.amount = amount
      this.deposit();

    },
    deposit: function () {

      this.reset();
      var p = this.getProfile();

      if (!p) {

        this.setError("Login", "Please login to proceed");
        this.$router.push({ name: 'login', params: {} });
        return;

      }

      if (this.amount < 1) {

        this.setError("Invalid Amount", "ENter amount atleast 1 KSH or above");
        return;

      }

      var vm = this;
      var path = process.env.VUE_APP_URL_DEPOSIT.replace("{profile_id}", p.d);

      var utm_source = this.getValue("utm_source");
      var utm_medium = this.getValue("utm_medium");
      var utm_campaign = this.getValue("utm_campaign");
      var referrer = this.getValue("referrer");

      var data = {
        amount: parseInt(this.amount),
        utm_source: utm_source,
        utm_medium: utm_medium,
        utm_campaign: utm_campaign,
        referrer: referrer,
      };

      vm.loading = 'loading';

      axios.post(path, JSON.stringify(data), {
        headers: {
          'api-key': vm.getAuth()
        },
      })
        .then(res => {

          vm.loading = '';
          var msg = res.data.message;
          vm.setSuccess("Deposit Initiated", msg);
          vm.message = 'Please enter amount you want to deposit';

        })
        .catch(err => {

          vm.loading = '';
          if (err.response) {

            if (parseInt(err.response.status) === 401 || parseInt(err.response.status) === 400 || parseInt(err.response.status) === 428) {

              vm.setError("Session Expired", "Your session on this device has expired");
              vm.logout();
              return;

            } else {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message));
            }

          } else if (err.request) {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err.request));

          } else {

            vm.setError("Network Error", "Check your network connection and try again")
            console.log(JSON.stringify(err));

          }

        })
    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },
    searchVisible: function () {

      console.log('this.current_page ==> ' + this.current_page);

      var searchVisible = this.current_page !== 'search';
      console.log('this.current_page ==> ' + this.current_page + ' searchVisible ==> ' + searchVisible);

      return searchVisible;

    },
  },
  data: function () {
    return {
      current_menu: '',
      amount: 49,
      iconSize: 24,
      iconBigSize: 30,
      mqttClient: false,
      myProfile: this.getProfile(),
      loading: '',
      message: 'Please enter amount you want to deposit',
    }
  },
  beforeDestroy: function () {

    console.log("beforeDestroy")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  },
  destroyed: function () {

    console.log("destroyed")
    if (this.mqttClient !== false) {

      this.mqttClient.end();
      this.mqttClient = false;

    }

  }
}
</script>

<style scoped>
ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

.contact-area {
  border-bottom: 1px solid #353C46;
  padding: 60px 0;
}

.contact-content p {
  font-size: 15px;
  position: relative;
}

.contact-content p::after {
  background: #353C46;
  bottom: -30px;
  content: "";
  height: 1px;
  left: 50%;
  position: absolute;
  transform: translate(-50%);
  width: 80%;
}

.contact-content h6 {
  color: #8b9199;
  font-size: 15px;
  font-weight: 400;
  margin-bottom: 10px;
}

.contact-content span {
  color: #353c47;
  margin: 0 10px;
}

.contact-social {
  margin-top: 30px;
}

.contact-social>ul {
  display: inline-flex;
}

.contact-social ul li a {
  border: 1px solid #8b9199;
  color: #8b9199;
  display: inline-block;
  height: 40px;
  margin: 0 10px;
  padding-top: 7px;
  transition: all 0.4s ease 0s;
  width: 40px;
}

.contact-social ul li a:hover {
  border: 1px solid #FAB702;
  color: #FAB702;
}

.contact-content img {
  max-width: 210px;
}

section,
footer {
  background: #1A1E25;
  color: #868c96;
}

footer p {
  padding: 40px 0;
  text-align: center;
}

footer img {
  width: 44px;
}
.topHeader{
z-index: 10;
}
</style>